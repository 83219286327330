.switch {
  position: relative;
  display: flex;

  span {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 21px;
    border-radius: 50px;
    cursor: pointer;
    background: #F0F0F0;
    border: 1px solid #F0F0F0;

    &::after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: #2898EC;
      left: 3px;
      transition: 0.2s;
      top: 2px;
    }

  }

  input[type=checkbox] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 0;
    left: 5px;
  }
  
}

input[type=checkbox]:checked+span {
  border: 1px solid #2898EC;
  background: #2898EC;

  &::after {
    background-color: #fff;
    left: 13px;
  }
}