.table {
  .table-scroll-x {
    max-height: 500px;
    width: 100%;
    overflow-x: auto;
  }
  .button-groupa {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 650px;
    margin-right: 40px;

    button {
      display: flex;
      align-items: center;
      margin-left: 32px;

      background: white;
      border: 0px;

      color: #000;
      text-align: center;

      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;

      cursor: pointer;
      img {
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }
  svg {
    margin-right: 8px;
  }
  .overflow {
    max-height: 500px;
    overflow-y: auto;
  }
  .checkbox {
    width: 45px;
  }

  .table-wrap
  {
    width: 100%;
    overflow: auto;
  }

  .table-groups {
    width: 100%;
    overflow: auto;
    background: #fff;
    margin-top: 16px;
    padding: 10px;
    border-radius: 10px 10px 0px 0px;
  }

  .tr {
    display: flex;
    margin-top: 10px !important;
    gap: 8px;
  }

  .th {
    display: inline-block;
    width: 100%;
    padding: 12px 12px;
    gap: 16px;
    border-radius: 8px;
    background: #f9f9f9;
    font-weight: normal;
    font-size: 16px;
    font-weight: 700;
    line-height: 125%;
    text-align: left;

    .th-group {
      margin-left: 12px;
      font-weight: normal;
    }
  }

  .td {
    display: inline-block;
    padding: 12px 12px;
    width: 100%;
    margin-left: 10px;
  }

  .td-action {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .block {
    .header-button {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      justify-content: space-between;
      align-items: center;

      .ant-input {
        display: flex;
        width: 280px;

        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        height: 34px;
        border-radius: 8px;
        background: var(--neutral-150, #f0f0f0);
      }

      .ant-btn {
        background: #2898ec;
        color: white;
        display: flex;
        align-items: center;
        padding: 10px 20px 10px 16px;
        height: 44px;

        color: var(--bw-900-w, #fff);

        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%;
        img {
          margin-right: 8px;
        }
      }
    }
  }
}
.modal-selectors {
  width: 240px;
}

.lists-users_group {
  width: 100%;
  padding-bottom: 30px;
}

.setting-down {
  background: #fff;
}