.my-page {
  background: #f9f9f9;
  border-radius: 28px 0 0 0;
  height: calc(100vh - 68px);
  overflow: auto;
  padding: 32px 32px 32px;
  width: 100%;

  .general-magazine-attendance_tab.active.disable {
    cursor: no-drop;
  }

  &_columns {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    height: 100%;
  }

  .left-column {
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    .students-tables {
      background: #fff;
      border-radius: 12px;
      margin-top: 12px;
      padding: 12px;
      flex-grow: 3;
      overflow: hidden;

      .lists {
        padding-bottom: 48px;
        height: 100%;
        overflow: auto;
        text-align: center;
        margin-top: 10px;

        &::-webkit-scrollbar {
          width: 0;
        }
      }

      .part {
        display: flex;
        justify-content: space-between;

        .students {
          display: flex;
          align-items: center;
          margin-left: 8px;
          margin-right: 8px;
          padding-left: 12px;
          gap: 16px;
          width: 80%;
          background: #f9f9f9;
          border-radius: 8px;
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .my-page
  {
    border-radius: 0;
  }
  .my-page_columns
  {
    flex-direction: column;
  }
}