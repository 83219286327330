$cardTransition: 0.2s;

.card {
  position: relative;
  width: 100%;
  max-width: 275px;
  padding: 12px;
  background: #FFFFFF;
  border-radius: 12px;
  transition: $cardTransition;
  
  .mini-card {
    width: 52px;
    height: 52px;
    background: #FFFFFF;
    padding: 8px;
    border: 2px solid #F0F0F0;
    border-radius: 8px;
    position: absolute;
    top: 12px;
    right: 12px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &_img {
    width: 202px;
    height: 212px;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &_category {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #B7B7B7;    
  }

  &_title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    color: #000000;    
    margin-top: 4px;
    margin-bottom: 8px;
  }

  &_description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #5C5C5C;
  }

  .to-cart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-top: 20px;
    
    .price {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px 0;
      width: 100%;
      max-width: 230px;
      background: #F9F9F9;
      border-radius: 8px;
      transition: $cardTransition;
      cursor: pointer;

      span {
        margin-left: 4px;
      }
    }
    
    .in-cart {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 48px;
      height: 48px;
      background: #2898EC;
      border-radius: 8px;
      cursor: pointer;

      &.check {
        background: #000;
      }
    }
  }
}

@media (max-width: 990px) {
  .card
  {
    max-width: none;
  }
}