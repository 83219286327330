.user-info {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 4px;
  border-radius: 12px;
  width: 100%;

  .hackcoins {
    margin-right: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 14px;
    border: 1px solid #191a1a;
    border-radius: 8px;

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 125%;
      color: #ffffff;
    }
  }

  .alert,
  .cart {
    width: 100%;
    max-width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-right: 4px;
    cursor: pointer;

    &:hover {
      background: #2898ec;
    }

    img {
      cursor: pointer;
    }
  }

  .avatar {
    margin-left: 12px;
    position: relative;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 100%;
    color: #ffffff;

    .user-level_count {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background: #fff;
      bottom: 0px;
      right: -6px;
      border-radius: 20px;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      color: #000;
    }
  }

  .user-name {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    text-align: right;

    .name {
      width: 100%;
      font-weight: 700;
      font-size: 16px;
      line-height: 125%;
      text-align: right;
    }

    .rank {
      display: inline-block;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      color: #b7b7b7;
    }
  }

  .wath-more {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
