.react-select {
  position: relative;
  display: flex;

  align-items: center;

  font-style: normal;
  font-size: 16px;
  line-height: 125%;
  color: #000000;

  padding: 12px 40px 12px 20px;

  border-radius: 8px;
  border: 1px solid #dfdfdf;
  cursor: pointer;
  white-space: nowrap;

  span {
    margin-left: 8px;
    font-weight: bold;
  }

  .select-options {
    position: absolute;
    left: 0;
    border-radius: 10px;
    z-index: 49;
    top: 100%;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid var(--neutral-150, #f0f0f0);
  }

  .select-option {
    padding: 14px;
  }

  .select-option:hover {
    background-color: #f0f0f0;
  }

  &_img {
    margin-right: 10px;
    position: absolute;
    right: 5px;
    transition: all 0.5s ease;

    &.activeSelector {
      margin-right: 10px;
      transform: rotate(-180deg);
    }
  }
}
