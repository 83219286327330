.tab {
  padding: 12px 20px;
  background: #f0f0f0;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #5c5c5c;

  .tab.active {
    color: black;
    background: #fff;
  }

  &:hover {
    background: #d1d1d1;
  }

  &:active {
    background: #000;
    color: #fff;
  }

  &s {
    display: flex;
    flex-wrap: wrap;

    gap: 8px;
  }
}

.activeCategory {
  padding: 12px 20px;
  background: #000;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #fff;
}
.noActiveCategory {
  padding: 12px 20px;
  background: #f0f0f0;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #5c5c5c;
}
