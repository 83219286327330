.school-chat {
  padding: 32px;
  padding: 42px;
  // padding-bottom: 100px;
  border-radius: 28px 0 0 0;
  background: #f9f9f9;
  width: 100%;
  height: 91vh;
  overflow: auto;

  &_column {
    background: #fff;
    border-radius: 12px;
    overflow: auto;

    &.left-column {
      width: 100%;
      max-width: 280px;
      padding: 8px;
      height: 100%;

      label {
        position: relative;
        
        .icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 14px;
  
          &.eye {
            left: unset;
            right: 14px;
            cursor: pointer;
          }
        }
  
        input {
          width: 100%;
          padding-left: 45px;
          font-size: 16px;
        }
      }

      .user-list {
        cursor: pointer;
        display: flex;
        gap: 12px;
        padding: 8px;
        border-radius: 8px;
        transition: 0.15s;
        position: relative;

        &:hover, &.active {
          background: #F0F0F0;
        }

        .user-avatar {
          width: 44px;
          height: 44px;
          background: #EBA215;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Noto Sans';
          font-weight: 600;
          font-size: 25px;
          line-height: 100%;
          color: #FFFFFF;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #52C41A;
            bottom: 5px;
            right: 0;
          }
        }

        &_div {

          .user-name {
            display: flex;
            justify-content: space-between;

            b {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 125%;
              color: #000000;
            }

            .time {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 100%;
              color: #5C5C5C;
              position: absolute;
              top: 15px;
              right: 12px;
            }
          }

          .last-message {
            .message {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 125%;
              color: #5C5C5C;
            }
          }
        }
        
        &s {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
  
    }

    &.right-column {
      padding: 10px;
      max-width: unset;
      position: relative;

      .user-activity {
        background: #F9F9F9;
        position: absolute;
        left: 10px;
        right: 10px;
        padding: 8px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        &_name {
          display: flex;
          align-items: center;
          gap: 12px;
          
          .user-avatar {
            width: 44px;
            height: 44px;
            background: #EBA215;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Noto Sans';
            font-weight: 600;
            font-size: 25px;
            line-height: 100%;
            color: #FFFFFF;
            position: relative;
          }

          b {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 125%;
            color: #000000;         
          }
        }

        &_last-activity {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 100%;
          color: #5C5C5C;
        }
        
      }

      .chat-board {
        display: flex;
        padding-top: 70px;
        flex-direction: column;
        gap: 10px;
        height: 90%;
        scroll-behavior: smooth;
        overflow-y: auto;

        .messages {
          padding: 14px 16px;
          background: #C9E4F8;
          border-radius: 12px;
          max-width: 70%;
          align-self: flex-end;
          word-wrap: break-word;

          &.talker {
            background: #F9F9F9;
            align-self: flex-start;
          }
        }

        &::-webkit-scrollbar {
          width: 0;
        }
      }

      label {
        position: relative;
        bottom: 0;
        width: 100%;

        .icon {

          &-image {
            path {
              stroke: #5C5C5C;
            }
          }

          &s {
            position: absolute;
            padding: 14px 12px;
            right: 0;
            border-radius: 8px;
            display: flex;
            gap: 12px;
            align-items: center;
          }
        }

        // .icon {
        //   position: absolute;
        //   top: 12px;
        //   cursor: pointer;

        //   &-image {
        //     right: 84px;
        //     top: 15px;

        //     path {
        //       stroke: #5C5C5C;
        //     }
        //   }

        //   &-smile {
        //     right: 48px;
        //   }

        //   &-mic {
        //     right: 12px;
        //   }
        //   &-send {
        //     right: 12px;
        //     top: 14px;
        //   }

        // }

        input {
          width: 100%;
          font-size: 16px;
          line-height: 125%;
          color: #5C5C5C;
        }

        textarea {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 14px 16px;
          padding-right: 112px;
          resize: none;
          width: 100%;
          height: 50px;
          background: #f9f9f9;
          border-radius: 8px;
          border: 0px;
          font-size: 16px;
          line-height: 125%;
          color: #5C5C5C;

          &::-webkit-scrollbar {
            width: 0;
          }
        }
      }
    }

    &s {
      display: flex;
      gap: 12px;
      width: 100%;
      height: 100%;
    }
  }
}

.messageAlert {
  .new-message_count {
    &::after {
      content: '23';
      position: absolute;
      font-style: normal;
      font-size: 12px;
      line-height: 100%;
      color: #FFFFFF;
      padding: 2px 6px;
      background: #2898EC;
      border-radius: 50px;
      right: 12px;
      bottom: 10px;         
    }
  }
}