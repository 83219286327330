.loader-courses {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 2px dashed var(--neutral-200, #dfdfdf);
  p {
    color: var(--neutral-300, #b7b7b7);
    text-align: center;

    /* Primery/H5 */
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
  }
}
