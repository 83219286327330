.magazine-block {
  background: #f9f9f9;
  border-radius: 28px 0px 0px 0px;
  padding: 32px;
  padding-right: 15px;
  width: 100%;
  padding-bottom: 100px;
  overflow: auto;
  height: 100vh;
}
