.header-button {
  justify-content: space-between;
}
.group-teacher {
  display: flex;
  align-items: center;
  .teacher {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .name-teacher {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: 8px;

      border-radius: 8px;
      border: 1px solid var(--neutral-200, #dfdfdf);
      padding: 6px 16px 6px 6px;
      gap: 8px;

      .select_teacher.rotate-90 {
        transition: transform 0.3s ease;
        transform: rotate(90deg);
      }
    }
  }
}

.font {
  color: var(--bw-900-w, #fff);
  margin-right: 8px;
  /* Bold/H4 */
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 20px */
}
.add-user-p {
  color: var(--bw-900-w, #fff);

  /* Bold/H4 */
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.button-group-admin .ant-input-focused {
  box-shadow: none;
}
.search-user {
  display: flex;
  width: 280px;
  border-radius: 8px;
  background: var(--neutral-150, #f0f0f0);
}
.buttons {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}
.button-group-admin {
  display: flex;
  width: 100%;
  justify-content: space-between;

  input {
    display: flex;
    width: 280px;
    padding: 12px;
    height: 36px;
    align-items: center;
    gap: 8px;

    border: 0px;
    border-radius: 8px;
    background: var(--neutral-150, #f0f0f0);
  }
}
.button-group-admin .ant-btn {
  display: flex;
  align-items: center;
  color: white;

  background: var(--primery-500, #2898ec);
  margin-left: 20px;
}
.info-user {
  display: flex;

  padding: 12px 12px;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;

  margin-right: 8px;

  border-radius: 8px;
  background: var(--neutral-100, #f9f9f9);
  p {
    color: var(--bw-900-b, #000);
    text-align: center;

    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
  }
  span {
    display: flex;
    align-items: center;

    color: var(--neutral-400, #5c5c5c);

    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;

    white-space: nowrap;
  }
}
.checkbox {
  display: flex;
  padding: 16px;
  margin-right: 8px;
  align-items: center;
  align-self: stretch;
  gap: 16px;

  border-radius: 8px;
  background: var(--neutral-100, #f9f9f9);

  input {
    width: 14px;
    height: 14px;
    border: 1px solid #f9f9f9;
    background: #f9f9f9;
  }
}
.lists-users {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }
}
.user-change {
  display: flex;
  align-items: center;
  justify-content: start;

  margin-left: 17px;
  margin-top: 20px;
  transition: background-color 0.2s ease, opacity 0.2s ease;

  .user-data {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 38px;

    .info-user-data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 650px;
      width: 100%;
      margin-left: 20px;
    }
  }
  .icon-user-settings {
    display: flex;
    align-items: center;
    margin-right: 70px;
    img {
      margin-right: 12px;
      cursor: pointer;
    }
  }
}
.hr-info {
  border: 1px solid #f0f0f0;
}
.setting-down {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 0px 0px 10px 10px;

  p {
    color: var(--neutral-400, #5c5c5c);
    /* Primery/H4 */
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 20px */
  }
  .button-groups {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    max-width: 600px;

    button {
      display: flex;
      align-items: center;

      background: white;
      border: 0px;

      color: #000;
      text-align: center;

      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;

      cursor: pointer;
    }
  }
}

.search-input {
  color: var(--neutral-400, #5c5c5c);
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.button-group-num {
  display: flex;
  margin-right: 40px;

  button {
    display: flex;
    align-items: center;

    background: white;
    border: 0px;

    color: #000;
    text-align: center;

    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;

    cursor: pointer;
  }
}

.user-button-group-admin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  input {
    color: var(--neutral-400, #5c5c5c);

    /* Primery/H5 */
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
  }

  .ant-btn {
    display: flex;
    align-items: center;
    color: white;
    height: 42px;
    width: 257px;
    background: var(--primery-500, #2898ec);
    margin-left: 20px;
    p {
      color: var(--bw-900-w, #fff);

      /* Bold/H4 */
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 20px */
    }
  }
}
// table
.table-group-user {
  width: 100%;
  background: white;
  padding: 0px 10px 0px 10px;
  margin-top: 20px;
  border-radius: 10px 10px 0px 0px;
}

.tr {
  margin: 0 !important;
  display: flex;
  gap: 8px;
}

.th {
  display: inline-block;
  width: 100%;
  padding: 12px 12px;
  gap: 16px;
  border-radius: 8px;
  background: #f0f0f0;
  font-weight: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 125%;
  text-align: left;

  .th-group {
    margin-left: 12px;
    font-weight: normal;
  }
}

.td {
  display: inline-block;
  padding: 12px 12px;
  width: 100%;
  margin: 0 !important;
}

.td-action {
  display: flex;
  align-items: center;
  gap: 12px;
}

.td {
  &-checkbox {
    margin: auto;
  }

  &-name {
    display: flex !important;
    align-items: center;
  }
}
