.table {
  background: black;
  width: 100%;

  height: 100vh;
  overflow: auto;

  .list_scroll {
    overflow-y: auto;
    position: relative;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  .block {
    padding: 32px;
    padding-bottom: 100px;
    width: 100%;
    height: 100vh;
    background: #f9f9f9;
    overflow: auto;
    display: flex;
    flex-direction: column;
    border-radius: 28px 0px 0px 0px;
  }
  .main {
    display: flex;

    flex-direction: column;
    margin-top: 24px;
    padding: 12px;
    isolation: isolate;
    width: 100%;
    height: 70vh;
    background: #ffffff;
    border-radius: 12px;
    .part {
      display: flex;
      width: 100%;
    }

    .students {
      display: flex;
      align-items: center;

      margin-left: 8px;
      margin-right: 8px;
      padding-left: 12px;
      gap: 16px;
      width: 80%;
      background: #f9f9f9;
      border-radius: 8px;

      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 125%;
        display: flex;
        align-items: center;
        color: #5c5c5c;
      }
    }
  }

  .button_group {
    display: flex;
    align-items: center;
    .buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      width: 100%;

      button {
        cursor: pointer;
        justify-content: center;
        align-items: center;
        padding: 12px 22px;
        gap: 10px;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        text-align: center;
        color: #5c5c5c;

        background: #f0f0f0;
        border-radius: 8px;
        border: 0px;
      }
      .active {
        justify-content: center;
        align-items: center;
        padding: 12px 20px;
        gap: 10px;
        color: white;
        background: #000000;
        border-radius: 8px;
        border: 0px;
      }
    }
  }
}

.table {
  background: black;
  width: 100%;
  height: 100vh;
  overflow: auto;

  .list_scroll {
    overflow-y: auto;
    position: relative;
    text-align: center;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  .block {
    padding: 32px;
    padding-bottom: 100px;
    width: 100%;
    height: 100vh;
    background: #f9f9f9;
    overflow: auto;
    border-radius: 28px 0px 0px 0px;
  }
  .main {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    padding: 12px;
    isolation: isolate;
    width: 100%;
    height: 70vh;
    background: #ffffff;
    border-radius: 12px;
    .part {
      display: flex;
      width: 100%;
    }

    .students {
      display: flex;
      align-items: center;

      margin-left: 8px;
      margin-right: 8px;
      padding-left: 12px;
      gap: 16px;
      width: 80%;
      background: #f9f9f9;
      border-radius: 8px;

      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 125%;
        display: flex;
        align-items: center;
        color: #5c5c5c;
      }
    }
  }

  .button_group {
    display: flex;
    align-items: center;
    .buttons {
      display: flex;
      gap: 8px;
      width: 100%;

      button {
        cursor: pointer;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        text-align: center;
        color: #5c5c5c;

        background: #f0f0f0;
        border-radius: 8px;
        border: 0px;
      }
      .active {
        justify-content: center;
        align-items: center;
        padding: 12px 20px;
        gap: 10px;
        color: white;
        background: #000000;
        border-radius: 8px;
        border: 0px;
      }
    }
  }
}

.students-tables {
  background: #fff;
  border-radius: 12px;
  margin-top: 12px;
  padding: 12px;
  padding-bottom: 60px;
  flex-grow: 3;
  overflow: hidden;

  .lists {
    height: 100%;
    overflow: auto;
    text-align: center;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .part {
    display: flex;
    margin-bottom: 10px;

    // justify-content: space-between;

    .students {
      display: flex;
      align-items: center;
      margin-left: 8px;
      margin-right: 8px;
      padding-left: 12px;
      gap: 16px;
      width: 80%;
      background: #f9f9f9;
      border-radius: 8px;
    }
  }
}

@media (max-width: 990px) {
  .table .block{
    border-radius: 0px;
  }
}