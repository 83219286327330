.settings {
  background: #f9f9f9;
  border-radius: 28px 0px 0px 0px;
  padding: 32px;
  padding-right: 15px;
  width: 100%;
  padding-bottom: 100px;
  overflow: auto;
  height: 100vh;

  .name-avatar {
    display: flex;
    width: 60px;
    height: 60px;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Noto Sans;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 44px */
  }
  .img-upload {
    width: 84px;
    height: 84px;
    border-radius: 50px;
  }
  .header {
    margin-top: 56px;
    width: 100%;
  }
  .settings_table {
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 32px;

    width: 720px;
    background: #ffffff;
    border-radius: 12px;
    .upload {
      display: flex;
      align-items: center;

      .download {
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        margin-left: 16px;
        gap: 8px;
        width: 172px;
        height: 44px;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 125%;
        color: #ffffff;
        background: #2898ec;
        border-radius: 8px;
        border: 0px;

        &:hover {
          background: #1384d9;
        }

        &:active {
          background: #000;
        }
      }
      .remove {
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        margin-left: 8px;
        gap: 8px;
        width: 114px;
        height: 44px;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 125%;
        display: flex;
        align-items: center;
        background: #f9f9f9;
        border-radius: 8px;
        border: 0px;

        &:hover {
          background: #000;
          color: #fff;
        }

        &:active {
          background: #2898ec;
          color: #fff;
        }
      }
    }
  }

  .part {
    width: 100%;

    input {
      width: 100%;
      margin-top: 12px;
    }

    &s {
      width: 100%;
      display: flex;
      gap: 12px;
    }
  }

  .textarea {
    display: flex;
    flex-direction: column;
    textarea {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 12px;
      margin-top: 12px;
      gap: 12px;
      resize: vertical;
      font-size: 16px;
      width: 640px;
      height: 100px;
      color: #5c5c5c;
      background: #f9f9f9;
      border-radius: 8px;
      border: 0px;
    }
    .textarea::placeholder {
      width: 616px;
      height: 24px;
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 125%;
      display: flex;
      align-items: center;
      color: #5c5c5c;
    }
  }

  #changePassLabel {
    display: flex;
    align-items: center;

    input {
      margin-left: 5px;
    }
  }

  .password {
    display: flex;
    margin-top: 24px;
    gap: 12px;

    input {
      width: 100%;
      margin-top: 12px;
    }
  }
  .number_email {
    margin-top: 24px;
    display: flex;
    gap: 12px;

    input {
      width: 100%;
      margin-top: 12px;
    }
  }

  .settings-notification {
    position: relative;
    p {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
  .save {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 128px;
    height: 48px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    color: #ffffff;
    background: #2898ec;
    cursor: pointer;
    border-radius: 8px;
    border: 0px;

    &:hover {
      background: #1384d9;
    }

    &:active {
      background: #000;
    }
  }
  .cancel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    cursor: pointer;
    gap: 8px;
    width: 103px;
    font-weight: bold;
    height: 48px;
    border: 0px;
    background: #f9f9f9;
    border-radius: 8px;

    &:hover {
      background: #000;
      color: #fff;
    }

    &:active {
      background: #2898ec;
      color: #fff;
    }
  }
}
