.avatar {
  background: #1384d9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;

  &.width1 {
    width: 32px;
    height: 32px;
  }
  &.width2 {
    width: 44px;
    height: 44px;
  }
  &.width3 {
    width: 84px;
    height: 84px;
  }
  .avatar-image-container {
    overflow: hidden;
  }

  .avatar-image {
    margin-top: 5px;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    height: 46px;
  }
  p {
    color: #fff;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Noto Sans;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 44px */
  }
}
