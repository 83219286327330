.courses {
  background: var(--neutral-100, #f9f9f9);
  border-radius: 28px 0px 0px 0px;
  padding: 32px;

  overflow: auto;
  height: 100vh;
}
.ant-input-prefix {
  margin-top: 5px;
}
.button-group-admins .ant-input {
  display: flex;

  height: 36px;
  margin-left: 8px;
  align-items: center;
  gap: 8px;
  background: #f0f0f0;
}

.button-group-admins {
  display: flex;
}
.button-group-admin .ant-btn {
  padding: 10px 20px 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;

  border-radius: 8px;
  background: var(--primery-500, #2898ec);
  .img-plus {
    margin-right: 8px;
  }
}

@media (max-width: 990px) {
  .courses
  {
    overflow: auto;
    border-radius: 0;
    padding-bottom: 100px;
  }
}