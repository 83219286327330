.header {
  width: 100%;;
  padding: 0 32px;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  margin-left: 0;
  height: 68px;
  
  &-title {
    width: 100%;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 100%;
    color: #FFFFFF;
    display: flex;
    align-items: center;

    &_img {
      margin-right: 20px;
      cursor: pointer;
    }
  }

  &-burger
  {
    display: none;
  }

  &-menu
  {
    display: none;
  }
}

@media (max-width: 990px) {
  .header{
    &-title {
      display: none;
    }
    &-burger
    {
      display: flex;

      &__icon
      {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    &-menu
    {
      position: fixed;
      left: 0;
      top: 68px;
      z-index: 999;
      width: 80%;
      height: calc(100% - 68px);
      background: black;
      display: flex;
      flex-direction: column;
      padding: 24px;
      overflow: auto;
    }
  }
}