.new-card {
  max-width: 280px;
  height: 200px;
  position: relative;
  width: 100%;

  &.favorite {
    .svg {
      fill: #000;

      path {
        fill: #000;
      }
    }
  }

  .svg {
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: 24;
    cursor: pointer;
  }
}

.course-card {
  color: #000;
  width: 100%;
  height: 200px;
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  transition: 0.1s;

  // &.favorite {
  //   background: #53DCA0;

  //   .svg {
  //     fill: #000;

  //     path {
  //       fill: #000;
  //     }
  //   }

  //   &_progress {
  //     &-diagram {
  //       .module-count_span {
  //         background-color: #fff;
  //       }
  //     }
  //   }
  // }

  &:hover {
    background: #2898ec;

    .svg {
      fill: #000;

      path {
        fill: #000;
      }
    }
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
  }

  .svg {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  &_description {
    margin-top: 5px;
    font-size: 14px;
    line-height: 125%;
    white-space: nowrap;
    overflow: hidden;
  }

  &_participants {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    &-list {
      text-transform: uppercase;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin-right: -8px;
      font-weight: 600;

      &:nth-child(1) {
        background: #15965d;
      }

      &:nth-child(2) {
        background: #f6545d;
      }

      &:nth-child(3) {
        background: #91e069;
      }

      &:nth-child(4) {
        background: #eba215;
      }
      &:nth-child(5) {
        background: #eba215;
      }

      &s {
        display: flex;
        position: relative;

        // &::after {
        //   content: '12';
        //   position: absolute;
        //   width: 100%;
        //   max-width: 33px;
        //   height: 33px;
        //   right: -33px;
        //   font-family: 'Noto Sans';
        //   font-weight: 600;
        //   font-size: 16px;
        //   line-height: 100%;
        //   color: #FFFFFF;
        //   border-radius: 50%;
        //   background: #000;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        // }
      }
    }

    &-all {
      cursor: pointer;
    }
  }

  &_progress {
    &-diagram {
      display: flex;
      gap: 4px;
      margin-bottom: 8px;

      .module-count_span {
        display: block;
        width: 100%;
        height: 10px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #dfdfdf;

        &.passed {
          border-color: #52c41a;
          background: #52c41a;
        }
        &.current {
          border-color: #fbad15;
          background: #fbad15;
        }
      }
    }

    &-description {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      line-height: 125%;
      color: #000000;
    }
  }
}
