.tab-card {
  width: 100%;
  max-width: 280px;
  background: #f0f0f0;
  border-radius: 12px;
  padding: 20px;
  padding-right: 12px;
  text-decoration: none;

  &:hover {
    background: #2898ec !important;
  }

  &.disabled {
    background: rgba(240, 240, 240, 0.5);
  }

  &.passed {
    border-color: #a3e3c7;
    background: #a3e3c7;
  }

  &.current {
    border-color: #fbd07d;
    background: #fbd07d;
  }

  .course-status {
    display: flex;
    justify-content: space-between;
  }

  &_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    color: #000;
  }

  &_description {
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #0b0b0b;
    margin-top: 4px;
  }

  &_progress {
    margin-top: 20px;
    &-diagram {
      display: flex;
      gap: 4px;
      margin-bottom: 8px;

      .module-count_span {
        display: block;
        width: 100%;
        height: 10px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #dfdfdf;

        &.passed {
          border-color: #52c41a;
          background: #52c41a;
        }
        &.current {
          border-color: #fbad15;
          background: #fbad15;
        }
      }
    }

    &-description {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      line-height: 125%;
      color: #000000;
    }
  }
}

@media (max-width: 990px) {
  .tab-card
  {
    max-width: none;
  }
}