.button-group-admins .ant-input-focused {
  box-shadow: none;
}

.custom-input {
  background: #2898ec;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.table-users {
  width: 100%;
}
img {
  cursor: pointer;
}
.th-group {
  white-space: none;
  color: var(--neutral-400, #5c5c5c);

  /* Primery/H5 */
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
}

.search-user {
  display: flex;
  width: 280px;
  border-radius: 8px;
  background: var(--neutral-150, #f0f0f0);
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button-group-admins {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;

  input {
    display: flex;
    width: 280px;

    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    height: 36px;

    border-radius: 8px;
    border: 0px;
    background: var(--neutral-150, #f0f0f0);
  }
}
.button-group-admins .ant-btn {
  display: flex;
  padding: 10px 20px 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  height: 44px;
  margin-left: 8px;

  border-radius: 8px;
  background: var(--primery-500, #2898ec);

  p {
    color: var(--bw-900-w, #fff);

    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
  }
}
.info-user {
  display: flex;

  padding: 12px 12px;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;

  margin-right: 8px;

  border-radius: 8px;
  background: var(--neutral-100, #f9f9f9);
  p {
    color: var(--bw-900-b, #000);
    text-align: center;

    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
  }
  span {
    display: flex;
    align-items: center;

    color: var(--neutral-400, #5c5c5c);

    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;

    white-space: nowrap;
  }
}
.checkbox {
  display: flex;
  padding: 16px;
  margin-right: 8px;
  align-items: center;
  align-self: stretch;
  gap: 16px;

  border-radius: 8px;
  background: var(--neutral-100, #f9f9f9);

  input {
    width: 14px;
    height: 14px;
    border: 1px solid #f9f9f9;
    background: #f9f9f9;
  }
}
.lists-users {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  width: 100%;
}
.user-change {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 10px !important;
  margin-left: 17px;

  transition: background-color 0.2s ease, opacity 0.2s ease;

  .user-data {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 38px;

    .info-user-data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 650px;

      width: 100%;
      p {
        white-space: nowrap;
      }
      // margin-left: 20px;
    }
  }
  .icon-user-settings {
    display: flex;
    align-items: center;
    margin-right: 70px;
    img {
      margin-right: 12px;
      cursor: pointer;
    }
  }
}
.hr-info {
  border: 1px solid #f0f0f0;
}
.setting-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
  p {
    color: var(--neutral-400, #5c5c5c);
    /* Primery/H4 */
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 20px */
  }
  .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 650px;
    margin-right: 10px;

    button {
      display: flex;
      align-items: center;
      margin-left: 32px;

      background: white;
      border: 0px;

      color: #000;
      text-align: center;

      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;

      cursor: pointer;
      img {
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }
}
.user-change.disabled {
  opacity: 0.7;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.ant-modal.css-dev-only-do-not-override-17a39f8 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 990px) {
  .button-group-admins{
    justify-content: flex-start;
  }
}