.custom-selector {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  width: 200px;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 100%;
  border: 0.5px dotted var(--neutral-150, #f0f0f0);
  align-self: stretch;
}

.response_task {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px;

  gap: 10px;

  border-radius: 8px;
  background: var(--primery-100, #f2faff);
  p {
    width: 662px;
  }
}
:where(.css-dev-only-do-not-override-18iikkb).ant-modal .ant-modal-title {
  color: #000;

  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%;
}

.select_task {
  display: flex;
  padding: 10px 10px 10px 20px;
  flex-direction: column;
  overflow: hidden;

  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--neutral-150, #f0f0f0);

  .span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    img {
      transform: rotate(90deg);
      transition: transform 0.3s ease;
    }
    .active_arrow {
      transform: rotate(0deg);
      transition: transform 0.3s ease;
    }
  }
  .title_task {
    color: #000;

    /* Primery/H3 */
    font-family: Nunito Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 20px */
  }
}
.custom-selector .options .option {
  display: flex;
  align-items: center;
}

.custom-selector .options .option img.taskVisible {
  margin-left: auto;
}

.taskVisible {
  display: flex;
  align-items: center;
}

.selected-option {
  display: flex;
  align-items: center;

  padding: 12px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.options {
  position: absolute;
  align-items: center;
  border: 0.5px dotted var(--neutral-150, #f0f0f0);
  top: 100%;
  width: 100%;
  overflow-x: auto;

  background-color: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);

  z-index: 2;
  img {
    margin-right: 10px;
  }
}

.selected-option img {
  margin-right: 10px;
}

.option {
  display: flex;

  align-items: center;
  width: 100%;
  border: 0.5px dotted var(--neutral-150, #f0f0f0);
  margin-right: 10px;

  padding: 14px;
}
.option:hover {
  margin-right: 10px;
  background-color: #f0f0f0;
}

.svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

//
.img-fluid {
  width: 640px;
}
.courses-head {
  p {
    width: 640px;
  }
}
