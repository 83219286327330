.notification {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 15px;
  display: flex;
  width: 372px;
  height: 406px;

  margin-top: 500px;
  padding-top: 16px;

  border-radius: 12px;
  margin-bottom: 16px;
  font-size: 14px;
  background: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  z-index: 100;

  h2 {
    margin-bottom: 16px;
  }
}

.header-notifications {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;

  width: 350px;
  padding: 10px;
  h2 {
    margin-top: 10px;
  }
}
.settings-notifications {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 12px;
    cursor: pointer;
  }
}

.messages-notifications {
  height: 320px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.messages-notification {
  display: flex;
  background-color: white;
  border-radius: 5px;
  padding: 10px;

  &_image {
    width: 72px;
    height: 72px;
    border-radius: 6px;
    display: flex;
    background: #f9f9f9;
    align-items: center;
    justify-content: center;

    .count {
      display: flex;
      align-items: center;
      color: #52c41a;

      svg {
        margin-left: 4px;
      }
    }
  }

  &_text {
    width: 100%;
    max-width: 219px;
    margin-left: 8px;
  }

  &.hackoins_up,
  &.level_up {
    .messages-notification_image {
      .count {
        color: #52c41a;
      }
    }

    &:hover {
      .messages-notification_image {
        background: #52c41a;

        .count {
          color: #fff;
        }

        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }

  &.hackoins_down,
  &.level_down {
    .messages-notification_image {
      .count {
        color: #f6222e;
      }
    }

    &:hover {
      .messages-notification_image {
        background: #f6222e;

        .count {
          color: #fff;
        }

        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }

  &:hover {
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 10px;
  }
}

.time {
  color: var(--neutral-400, #5c5c5c);
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  margin-top: 5px;
}

.message {
  color: var(--bw-900-b, #000);
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
}

.noNotification {
  color: #000;
  text-align: center;
  padding: 80px 0px 0px 120px;
}
