.accordions {
  margin-top: 28px;
  padding-left: 25px;

  .accordion {
    position: relative;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    cursor: pointer;

    .contentContainer {
      display: flex;
      align-items: center;
      .img {
        img {
          margin: 0;
          padding: 0;
          width: 10px;
          height: 10px;
        }
      }
    }

    span {
      display: inline-block;
      padding: 10px 0;
    }

    .arrow {
      margin-top: 10px;
      position: absolute;
      left: -18px;
      top: 16px;
      transform: rotate(-90deg);
      transition: 0.3s;

      &.plus {
        left: 70%;
        top: 10px;
      }
    }

    .sublists {
      margin-top: 10px;
      display: none;
      .active {
        background: #dfdfdf8f;
      }
      .sublist {
        margin-left: -20px;
        cursor: pointer;
        list-style: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 125%;
        padding: 8px 15px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background: #dfdfdf8f;
        }

        svg {
          cursor: pointer;
        }
      }
    }

    &.active {
      .arrow {
        transform: rotate(0deg);
      }

      .sublists {
        display: unset;
      }
    }
  }
}
