.right-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 310px;
  .img-upload {
    width: 84px;
    height: 84px;
    border-radius: 50px;
  }
  .user-profile {
    background: #fff url('../../../assets/img/RightColumnCard_bg.png') no-repeat;
    padding: 20px;

    .progress {
      margin-top: 24px;
      text-align: center;

      .avatar {
        margin: auto;
        width: 100%;
        max-width: 80px;
        height: 80px;
        display: flex;
        align-content: center;
        justify-content: center;
        font-family: 'Noto Sans';
        font-weight: 600;
        font-size: 44px;
        line-height: 100%;
        color: #ffffff;
      }

      .level {
        margin-top: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        color: #000000;
      }

      .experience {
        margin-top: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 125%;
        color: #b7b7b7;

        span {
          color: #000;
        }
      }
    }

    .name {
      margin-top: 16px;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 100%;
      color: #000000;

      .position {
        margin-top: 4px;
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        color: #b7b7b7;
      }
    }

    .activity {
      margin-top: 20px;

      .top-section {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        b {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 125%;
          color: #000000;
        }

        span {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 125%;
          color: #5c5c5c;
        }
      }

      .bottom-section {
        .activity-date {
          font-size: 12px;
          line-height: 100%;
          color: #000;
          background: #f0f0f0;
          border-radius: 6px;
          padding: 6px 14px;

          &.absent,
          &.present,
          &.was {
            color: #fff;
          }

          &.absent {
            background: #f6222e;
          }

          &.was {
            background: #3cb501;
          }

          &.present {
            background: #fbad15;
          }

          &s {
            display: flex;
            gap: 4px;
          }
        }
      }
    }
  }

  .alert {
    height: 272px;
    overflow: auto;

    .right-column-card_title {
      margin-bottom: 28px;
    }
  }

  .last-active {
    &_course {
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 8px;

      &s {
        height: 149px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 0;
        }
      }

      &:hover {
        background: #f9f9f9;
      }

      &-img {
        align-self: flex-start;
        display: flex;
      }

      &:last-child {
        margin-top: 8px;
      }

      &-text {
        display: flex;
        justify-content: space-between;
        margin-left: 12px;
        width: 100%;

        .course-name {
          margin-bottom: 8px;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;
          color: #000000;
          white-space: nowrap;
          margin-right: 10px;
        }

        .course-description {
          margin-top: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 125%;
          color: #191a1a;
          display: block;
        }

        .last-active_time {
          font-style: normal;
          color: #5c5c5c;
          font-size: 12px;
          font-weight: 400;
          line-height: 100%;
        }
      }
    }
  }
}
