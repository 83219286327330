.right-column-card {
  padding: 20px;
  width: 100%;
  background: #FFFFFF;
  overflow: hidden;
  border-radius: 12px;

  &_title {
    padding: 0 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    color: #000000;
  }
}