.module-card-container {
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 32px 32px;
  flex-direction: column;
  border-radius: 28px 0px 0px 0px;
  background: var(--neutral-100, #f9f9f9);
  img {
    cursor: pointer;
  }
}
.description-module {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 240px;
}
.flex-modalCard {
  display: flex;
  width: 100%;

  flex-wrap: wrap;
  justify-content: flex-start;
}
.modalCards {
  width: calc(25.5% - 1.4%);
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 12px;
}

.select-module {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 10px;
}

.add-module {
  display: flex;
  align-items: center;
  color: white;
  height: 42px;
  width: 184px;
  .img-plus {
    margin-right: 10px;
  }
}

@media (max-width: 990px) {
  .module-card-container
  {
    border-radius: 0;
    overflow: auto;
    padding-bottom: 100px;
  }
  .modalCards{
    width: 100%;
  }
}