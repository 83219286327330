.site-map {
  width: 100%;
  padding: 32px;
  background-color: #f9f9f9;
  border-radius: 28px 0 0 0;
  height: 100vh;
  padding-bottom: 100px;
  overflow: auto;

  &_columns {
    display: flex;
    gap: 40px;
  }

  h3,
  h4,
  h5,
  h6 {
    margin-top: 10px;
  }

  pre {
    font-size: 14px;
  }

  .left-column {
    width: 100%;
    max-width: 295px;
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;
    height: 100%;

    &_title {
      font-weight: 700;
      font-size: 20px;
      line-height: 100%;
    }

    .add-theme {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 125%;
      cursor: pointer;
    }
  }

  .rigth-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .input-task-result {
      .text-field {
        margin-top: 20px;
        width: 640px;
      }
    }
    .courses-head {
      width: 640px;
      display: flex;
      flex-direction: column;

      p:has(> b) {
        padding-top: 20px;
      }
      div:has(> img) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }

  .div-img-configuration {
    background-size: cover;
    background-position: center;
    .img-fluid {
      width: 100%;
    }
  }
  button {
    width: 300px;
    height: 50px;
    margin-top: 24px;
    cursor: pointer;
  }
  .button-check {
    border: none;
    outline: none;

    cursor: pointer;
    border-radius: 8px;
    padding: 12px 24px;
    color: #fff;
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    background-color: #5c5c5c;
  }
  .button-complited {
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    color: #fff;
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    background-color: #2898ec;
    transition: .3s;

    &:disabled {
      opacity: 0.5;
    }
  }

  .module-count_span {
    background: #f0f0f0;
  }
}

@media (max-width: 990px) {
  .site-map
  {
    border-radius: 0;
  }
  .site-map_columns
  {
    flex-direction: column;
  }
  .rigth-column
  {
    overflow: auto;
  }
}