.course-card-container {
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
  gap: 10px;
  img {
    cursor: pointer;
  }
}

.courseCards {
  background: white;
  border-radius: 8px;
  padding: 20px;
  min-width: 278px;
  overflow: hidden;
}

.course-card-image {
  width: 100%;
}

.course-card-details {
  display: flex;
  align-items: center;
  line-height: 1.5;
}

.icon-course {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.course-card-title {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course-card-description {
  font-size: 14px;
  color: #555555;

  margin: 0;
}
.info-courses {
  display: flex;
  margin-top: 10px;
  gap: 20px;

  color: #555555;
  font-size: 14px;
}

.info-text {
  margin: 0;
  margin-bottom: 16px;
}

.info-value {
  margin-top: 20px;
  color: #f0f0f0;
  font-weight: bold;
  color: inherit;
}
.icon-change {
  display: flex;
  align-content: center;
  justify-content: space-between;

  margin-top: 12px;
}
.hr-line {
  border: 0.5px solid #f0f0f0;
}

@media (max-width: 567px) {
  .courseCards
  {
    width: 100%;
  }
}
