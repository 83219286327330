.input {
  padding: 12px 12px;
  outline: none;
  font-size: 16px;
  font-family: 'Nunito Sans';
  border: none;
  background: #f9f9f9;
  border-radius: 8px;
  line-height: 125%;
  display: flex;
  align-items: center;
}
