.noConnect-items {
  display: flex;
  align-items: center;
  gap: 8px;

  .img {
    width: 18px;
  }
  span {
    color: #5c5c5c;
    font-family: 'Nunito Sans';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
}
