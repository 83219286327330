.add-courses {
  background: #f9f9f9;
  border-radius: 28px 0px 0px 0px;
  padding: 32px;
  padding-bottom: 100px;
  height: 100vh;
  width: 100%;
  overflow: auto;

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
  }

  &_title {
    margin-top: unset;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
  }

  .error-message {
    margin-top: 10px;
    color: var(--danger-600, #d80914);
  }
  .error {
    color: red;
    margin-top: 10px;
  }

  &_form {
    background-color: #fff;
    padding: 40px;
    width: 100%;
    max-width: 720px;
    border-radius: 12px;
  }

  &_general {
    .course {
      &-name {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        gap: 12px;

        label {
          width: 100%;

          span {
            color: #f6222e;
          }

          input {
            margin-top: 12px;
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            line-height: 125%;
            color: #5c5c5c;
          }
        }
      }

      &-format {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        margin-top: 20px;
        margin-bottom: 20px;

        label {
          width: 100%;

          select {
            margin-top: 12px;
            width: 100%;
            padding: 12px;
            background: #f9f9f9;
            border-radius: 8px;
            border: none;
            font-size: 16px;
            line-height: 125%;
            cursor: pointer;
            color: #5c5c5c;

            appearance: none;
            background-image: url('../../assets/img/icons/arrow.svg');
            background-position: calc(100% - 12px) center; /* Adjusted background position */
            background-repeat: no-repeat;

            &::-webkit-validation-bubble-arrow-clipper {
              opacity: 0;
            }
          }
        }
      }

      &_data-holding {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        margin-top: 20px;
        margin-bottom: 20px;

        label {
          width: 100%;
          position: relative;

          input {
            padding-left: 20px;
            margin-top: 12px;
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            line-height: 125%;
            color: #5c5c5c;
            flex-direction: row-reverse;

            &::-webkit-calendar-picker-indicator {
              opacity: 0;
              z-index: 150;
            }
          }

          img {
            position: absolute;
            top: 37px;
            left: 12px;
          }
        }
      }
    }

    label {
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      width: 100%;

      select {
        margin-top: 12px;
        width: 100%;
        padding: 12px;
        background: #f9f9f9;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        line-height: 125%;
        cursor: pointer;
        color: #5c5c5c;

        appearance: none;
        background-image: url('../../assets/img/icons/arrow.svg');
        background-position: calc(100% - 12px) center; /* Adjusted background position */
        background-repeat: no-repeat;
      }

      textarea {
        min-height: 100px;
        width: 100%;
        background: #f9f9f9;
        border-radius: 8px;
        border: none;
        resize: vertical;
        margin-top: 12px;
        padding: 12px;
        font-size: 16px;
        line-height: 125%;
        color: #5c5c5c;
      }
    }
  }

  &_access-course {
    .add-courses_access-description {
      margin-top: 20px;
    }
    .access-course_add-list {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .access-course {
        &_list {
          line-height: 125%;
          padding: 12px;
          background: #f9f9f9;
          border-radius: 8px;

          &s {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
            margin-top: 12px;
          }
        }

        &_btn {
          button {
            width: 44px;
            height: 44px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f9f9f9;
          }
        }
      }
    }
  }

  &_access {
    margin-top: 32px;

    p {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 125%;
    }
  }

  &_button {
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;

    &-cancellation {
      color: #000;

      &:hover {
        &:hover {
          background-color: #dfdfdf;
        }

        &:active {
          background-color: #000000;
          color: #fff;
        }
      }
    }

    &s {
      margin-top: 32px;
      display: flex;
      gap: 8px;
      .cancel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        cursor: pointer;
        gap: 8px;
        width: 103px;
        font-weight: bold;
        height: 48px;
        border: 0px;
        background: #f9f9f9;
        border-radius: 8px;

        &:hover {
          background: #000;
          color: #fff;
        }

        &:active {
          background: #2898ec;
          color: #fff;
        }
      }
    }
  }

  .footnote {
    margin-top: 32px;
    font-size: 14px;
    line-height: 125%;
    color: #5c5c5c;

    span {
      color: #d80914;
    }
  }
}
