.menu {
  position: relative;
  padding: 24px 24px 40px 32px;
  width: 100%;
  max-width: 232px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  .more-project {
    margin-top: 40px;

    .my-project {
      display: flex;
      width: 75px;
      padding: 4px 8px;
      align-items: flex-start;
      gap: 10px;
      margin-bottom: 15px;

      border-radius: 4px;
      background: var(--neutral-500, #191a1a);

      color: var(--neutral-300, #b7b7b7);

      /* Primery/H5 */
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  &-link_lists {
    display: flex;
    flex-direction: column;
    margin-top: 44px;
  }

  a {
    color: #b7b7b7;
    text-decoration: none;
  }

  &-lists {
    display: flex;
    flex-direction: column;
  }

  a.active {
    .menu-list {
      &:after {
        border-left: 5px solid #1384d9;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      color: #fff;

      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }

  .arrowDown {
    position: absolute;
    right: 24px;
    top: 173px;
    transform: rotate(-90deg);
    transition: 0.05s;

    &.open {
      transform: rotate(90deg);
    }
  }

  &-list {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #b7b7b7;
    cursor: pointer;
    padding: 12px 0;

    &s {
      flex-grow: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -25px;
      bottom: 0;
      right: 0;
      border: 5px solid transparent;
    }

    svg {
      margin-right: 12px;
    }

    &:hover {
      &:after {
        border-left: 5px solid #1384d9;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      color: #fff;

      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }

  .signOut-faq {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    color: #b7b7b7;

    a.active {
      .faq,
      .sign-out {
        &:after {
          border-left: 5px solid #1384d9;
        }

        color: #fff;

        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }

    .faq,
    .sign-out {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 12px 0;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -25px;
        bottom: 0;
        right: 0;
        border: 5px solid transparent;
      }

      svg {
        margin-right: 12px;
      }

      &:hover {
        &:after {
          border-left: 5px solid #1384d9;
        }

        color: #fff;

        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}

.sub-menu {
  &_list {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    color: #b7b7b7;
    padding: 8px 0;
    border: 1px solid transparent;

    &::before {
      content: '';
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      border: 3px solid #b7b7b7;
      margin-right: 8px;
    }

    &-active {
      color: #ffffff;

      &::before {
        border: 3px solid #1384d9;
        background: #1384d9;
      }
    }

    &s {
      margin-left: 32px;
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

@media (max-width: 990px) {
  .menu{
    display: none;
  }
}