.students_table {
  padding: 8px 5px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;

  .avatar {
    margin-left: 25px;
    margin-right: 12px;
  }

  .number_name {
    display: flex;
    align-items: center;

    .list_number {
      width: 40px;
      text-align: center;
    }

    .trophy {
      width: 40px;
      text-align: center;
    }
  }
  .point {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 200px;

    img {
      margin-right: 10px;
    }
  }

  &.current-user {
    background: #2898ec;
    color: #fff;
    position: sticky;
    top: 0;
    bottom: 0px;

    svg {
      margin-right: 10px;
    }
  }
}
