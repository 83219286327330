.accordion {
  background: white;

  &Header {
    padding: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    transition: all 0.3s ease;

    cursor: pointer;
    &:hover {
      border-radius: 8px;
      background-color: #f5f5f5;
    }
    &Content {
      display: flex;
      overflow: hidden;
      color: #000;
      font-family: 'Nunito Sans';
      font-size: 16px;
      font-weight: 400;

      &ArrowDown {
        img {
          position: relative;
          top: 1px;
        }
      }
    }
    &Title {
      &Text {
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    h2 {
      color: #000;
      font-family: 'Nunito Sans';
      .isActive {
        transform: rotate(90deg);
      }

      .titleEditInput {
        border: none;
        margin-left: 4px;
      }

      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }
  }

  .titleEditInput {
    border: none;
    margin-left: 4px;
  }
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.isActive {
  display: flex;
  transform: rotate(90deg);
  transition: all 0.3s ease;
}

.rightSections {
  display: none;
}

.hovered {
  display: flex;
  gap: 4px;
}
