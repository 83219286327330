.forgot-password {
  &_description {
    font-size: 16px;
    line-height: 125%;
    color: #5c5c5c;
    margin-top: 12px;
  }

  &-block {
    position: relative;
    margin-left: auto;
    width: 480px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    padding: 0 72px;
    border-radius: 44px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .unlock-img {
      line-height: 100%;
      margin-bottom: 20px;
    }

    .oneC
    {
      cursor: default;
      position: absolute;
      top: 72px;
      left: 72px;
    }
  }

  &_form {
    margin-top: 32px;

    label {
      position: relative;

      .icon {
        position: absolute;
        top: 9px;
        left: 14px;
      }

      input {
        width: 100%;
        margin-bottom: 40px;
        padding-left: 45px;
      }
    }

    input[type='checkbox'] {
      width: unset;

      &:checked {
        background-color: red;
      }
    }

    button {
      margin-top: 32px;
      width: 100%;
    }

    .forgot-password_link {
      border: none;
      outline: none;
      cursor: pointer;
      width: 100%;
      display: inline-block;
      text-decoration: none;
      text-align: center;
      font-size: 14px;
      border-radius: 8px;
      padding: 12px 24px;
      background: #000;
      color: #fff;
      margin-bottom: 12px;
      font-weight: bold;

      &:hover {
        background-color: #2898ec;
      }

      &:active {
        background-color: #000000;
      }

      &.back {
        background: #f9f9f9;
        color: #000;

        &:hover {
          background-color: #000000;
          color: #fff;
        }

        &:active {
          background-color: #000000;
          color: #fff;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 990px) {
  .forgot-password-block{
    width: 100%;
    border: none;
    padding: 0 22px;
  }
}