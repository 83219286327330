$padding: 32px;
$wid: 32px;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}

li {
  list-style: none;
}

.container {
  width: 100%;
  margin: 0 auto;
}

body {
  overflow: hidden;
  background: #000;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}