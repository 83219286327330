$transitionSpeed: 0.1s;

.faq {
  width: 100%;
  height: 100vh;
  background: #f9f9f9;
  border-radius: 28px 0px 0px 0px;
  padding: 32px;
  overflow: auto;

  &_title {
    font-size: 20px;
    margin-bottom: 16px;
    font-weight: 700;
    line-height: 100%;
  }
  
  &-accordion {
    background: #FFF;
    position: relative;
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;
    
    hr {
      margin: 12px 0;
    }

    .close {
      position: absolute;
      background: transparent;
      border-radius: 10px;
      width: 100%;
      left: 0;
      top: 0;
      height: 100%;

      .close-img {
        position: absolute;
        right: 10px;
        top: 17px;
        transform: rotate(90deg);
        transition: $transitionSpeed;

        &.active {
          transform: rotate(0deg);
        }
      }
    }
    
    &_question {
      font-size: 16px;
      font-weight: 700;
      line-height: 125%;
    }

    &_answer {
      font-size: 14px;
      font-weight: 400;
      line-height: 125%;
      color: #5C5C5C;

      height: 0;
      overflow: hidden;
      
      ul {
        li {
          font-size: 17px;
        }
      }
      
      &.active {
        height: 100%;
        max-height: 200px;
      }
    }

    &s {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}

