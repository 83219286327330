.card-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  position: absolute;
  width: 666px;
  height: 480px;
  background: #fff;
  border-radius: 12px;
  right: 30px;
  top: 83px;
  color: #000;
  gap: 12px;
  z-index: 100;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);

  &_title {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
    }

    .btn_delete-select {
      display: flex;
      align-items: center;
      font-weight: 700;
      line-height: 100%;

      img {
        margin-right: 12px;
      }
    }
  }

  &_product-list {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-radius: 12px;
    padding: 12px;
    flex: 1;
    transition: 0.1s;

    &:hover {
      background: #f6f5f5;
    }

    label {
      cursor: pointer;
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid #b7b7b7;
      border-radius: 4px;
    }

    .select-product {
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      visibility: hidden;
      position: absolute;

      &:hover + label {
        border-color: #2898ec;
      }

      &:checked + label {
        border-color: #2898ec;
        background: #2898ec url("../../assets/img/icons/checkmark.png")
          no-repeat;
        background-position: center;
      }
    }

    .product {
      &-img {
        width: 52px;
        height: 52px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &-name {
        &_title {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 125%;
          margin-bottom: 4px;
        }

        &_description {
          color: #5c5c5c;
          font-size: 14px;
          font-weight: 400;
          line-height: 125%;
        }
      }

      &-price {
        .total-price {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          font-size: 16px;
          font-weight: 700;
          line-height: 125%;

          img {
            margin-right: 4px;
          }
        }

        .one-thing_cost {
          display: flex;
          gap: 4px;
          color: #b7b7b7;
          font-size: 14px;
          font-weight: 400;
          line-height: 125%;
        }
      }

      &-count {
        display: flex;
        align-items: center;
        gap: 4px;

        &_number {
          width: 44px;
          height: 32px;
          padding: 10px 8px;
          border-radius: 4px;
          border: 2px solid #f9f9f9;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .increment,
        .decrement {
          cursor: pointer;
          padding: 8px;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f9f9f9;
          border-radius: 4px;
        }
      }

      &-remove {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    &s {
      flex: 1;
      overflow-y: auto;
    }
  }

  .button {
    width: 100%;
    font-family: Nunito Sans;
    font-weight: 700;
    line-height: 125%;
    align-self: flex-end;
  }
}
