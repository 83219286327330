.general-magazine-attendance {
  background: #f9f9f9;
  border-radius: 28px 0px 0px 0px;
  padding: 32px;
  width: 100%;

  height: 100vh;

  .overlay-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 2;
  }

  .custom-button {
    display: flex;
    height: 44px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 8px;
    background: var(--primery-500, #2898ec);
    color: #fff !important;
    cursor: pointer;
    :hover {
      color: #fff !important;
    }
  }

  .table-container {
    position: relative;
    padding: 12px;
    background: #fff;

    border-radius: 28px 12px 12px 12px;
  }

  &_tab {
    padding: 15px 22px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    color: #5c5c5c;
    background: #f0f0f0;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background-color: #d1d1d1;
    }

    &:active {
      color: #fff;
      background-color: #000000;
    }

    &.active {
      color: #fff;
      background: #000000;

      &:hover {
        color: #fff;
        background: #000000;
      }
    }

    &:hover {
      background-color: #d1d1d1;
    }

    &:active {
      color: #fff;
      background-color: #000000;
    }

    &s {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .general-magazine-attendance_board-table {
    border-collapse: collapse;
    width: 100%;
  }

  &_information-tab {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    color: #000000;
    padding: 12px 20px 12px 16px;
    background: #f9f9f9;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: #000;
      color: #fff;
    }

    &:active {
      background: #2898ec;
      color: #fff;
    }

    span {
      margin-left: 5px;
    }

    svg {
      margin-right: 8px;
    }

    &s {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  .attendance-columns {
    width: 100%;
    display: flex;

    &_line {
      width: 100%;
      display: flex;
    }
  }

  &_board {
    margin-top: 12px;

    &-column {
      &_title {
        font-size: 16px;
        font-weight: 600;
        line-height: 100%;
        border: 0.5px dotted var(--neutral-150, #f0f0f0);
        padding: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &.name-column {
          max-width: 204px;
          position: sticky;
          left: 0;
          z-index: 1;
          background: #f9f9f9;
          padding-right: 10px;
        }

        &.attendance-column {
          max-width: 312px;
          width: 100%;
        }
      }

      .student-list {
        padding: 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 115%;
        color: #5c5c5c;
        border: 0.5px dotted var(--neutral-150, #f0f0f0);
        position: sticky;
        left: 0;
        z-index: 1;
        background: #f9f9f9;
        max-height: calc(100vh - 200px);
        overflow-x: auto;
        overflow-y: hidden;
      }

      &s {
        display: flex;
      }
    }
  }
  .last-name,
  .tasks,
  .users-name {
    border: 1px solid #f0f0f0;
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: sticky;
    background-color: #fff;
    z-index: 2;
  }
  .select-tasks {
    border: 1px solid #f0f0f0;
    background-color: #fff;
    z-index: 2;
  }

  &_board {
    &-table {
      table-layout: fixed;
      white-space: nowrap;

      .last-name {
        width: 260px;
      }

      .tasks {
        cursor: pointer;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          width: 200px;
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .general-magazine-attendance {
    border-radius: 0;
  }
  .table-container {
    overflow: auto;
  }
  .general-magazine-attendance .general-magazine-attendance_board-table {
    overflow: auto;
    width: auto;
  }
}
