.button {
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  padding: 12px 24px;
  color: var(--bw-900-w, #fff);
  text-align: center;
  /* Bold/H4 */
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 20px */

  &-color_green {
    background-color: #2898ec;

    &:hover {
      background-color: #1384d9;
    }

    &:active {
      background-color: #000000;
    }
  }

  &-color_gray {
    background-color: #f9f9f9;

    &:hover {
      background-color: #5c5c5c;
    }

    &:active {
      background-color: #000000;
    }
  }

  &-color_dark {
    background-color: #f0f0f0;

    &:hover {
      background-color: #d1d1d1;
    }

    &:active {
      color: #fff;
      background-color: #000000;
    }
  }

  &-color_black {
    background-color: #000;

    &:hover {
      background-color: #2898ec;
    }

    &:active {
      background-color: #000000;
    }
  }

  .access-course {
    &_lists {
      display: flex;
    }
  }
}
