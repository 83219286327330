h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
}

.my-courses {
  display: flex;
  margin-top: 20px;
  height: 200px;
  gap: 12px;

  .card-link {
    display: inline-block;
    width: 100%;
    text-decoration: none;
  }
}
