.shop {
  width: 100%;
  padding: 32px;
  background-color: #f9f9f9;
  border-radius: 28px 0 0 0;
  height: 100vh;
  padding-bottom: 100px;
  overflow: auto;

  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 20px;
  }

  .disable {
    cursor: no-drop;
  }

  .empty {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
  }
}
