.sent {

  .sent-img {
    margin-top: 20px;
  }

  &-block {
    position: relative;
    margin-left: auto;
    width: 480px;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    padding: 0 72px;
    border-radius: 44px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .links-to-email {
      margin-top: 40px;
      justify-content: center;
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .unlock-img {
      line-height: 100%;
      margin-bottom: 20px;
    }
  }

  &_description {
    margin-top: 12px;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 125%;
    color: #5C5C5C;
  }
  
  &_link {
    border: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    border-radius: 8px;
    padding: 12px 24px;
    background: #000;
    color: #fff;
    margin-bottom: 12px;

    &:hover {
      background-color: #2898EC;
    }
  
    &:active {
      background-color: #000000;
    }
  }
}