.error-page {
  padding: 10px;
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_img {
    margin-top: 145px;
    margin-bottom: 60px;

    @media (max-width: 1600px) {
      margin-top: 50px;
      margin-bottom: 30px;
    }

    @media (max-width: 1150px) {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  &_btn_to_main {
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; 
    padding: 12px 20px;
    border: 0;
    border-radius: 8px;
    background: #000;
  }
}