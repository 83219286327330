.root {
  height: 100vh;
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 18px 0 0 0;
  overflow-y: auto;
  scroll-behavior: smooth;
  .items {
    display: flex;
    gap: 20px;

    .left-section {
      display: flex;
      flex-direction: column;
      width: 288px;
      gap: 20px;
      .select {
        padding: 32px 0 0 32px;
        .text-module {
          color: #5c5c5c;
          font-family: "Nunito Sans";
          font-size: 16px;
          font-weight: 400;
        }
        .order-module {
          color: #000;
          font-family: "Nunito Sans";
          font-size: 16px;
          font-style: normal;
        }
      }
      .description-module {
        margin-left: 32px;

        .text-field {
          width: 100%;
        }
      }
      .accordion-theme-and-task {
        padding: 20px;
        background-color: #fff;
        margin-left: 32px;
        border-radius: 12px;

        .theme-and-tasks {
          padding: 8px;
          font-family: "Nunito Sans";
          font-size: 20px;
        }
        .addThemeButton {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding-top: 10px;
          .plus-img {
            width: 18px;
            height: 18px;
            img {
              width: 100%;
            }
          }
          span {
            color: #000;
            font-family: "Nunito Sans";
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
    .editor {
      width: 100%;
    }
  }
}

.div-img-configuration {
  background-size: cover;
  background-position: center;
  .img-fluid {
    width: 100%;
  }
}

.find-references__files-wrap {
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  gap: 12px;
}

.find-references__files {
  display: flex;
  align-items: center;
  gap: 8px;
}

.find-references__files-title {
  color: #5c5c5c;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.find-references__files-p-wrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.find-references__files-p {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #5c5c5c;

  max-width: 227px;
}

.find-references__files-btns {
  display: flex;
  align-items: center;
  gap: 4px;
}

.find-references__files-button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 44px;
  background-color: #f0f0f0;

  border: none;
  border-radius: 8px;

  cursor: pointer;

  a {
    display: flex;
  }
}

.reward-quest {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 288px;
  padding: 0 0 0 32px;
  .point {
    color: #5c5c5c;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .reward-point {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 12px 16px 12px 20px;
    background: #fbad15;
    height: 40px;
    text-align: center;
    border: none;
    color: var(--bw-900-w, #fff);
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
  }
}

.addTaskBtn {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: none;

  img {
    width: 100%;
  }
}
.deleteIcon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: none;

  img {
    width: 100%;
  }
}
.editButton {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: none;

  img {
    width: 100%;
  }
}

.buttons-actions {
  display: flex;
  gap: 10px;
  z-index: 100;
  .delete-task-btn {
    background: none;
    border: none;
    width: 16px;
    height: 16px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
  .edit-task-btn {
    background: none;
    border: none;
    width: 16px;
    height: 16px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
  .add-task-btn {
    background: none;
    border: none;
    width: 16px;
    height: 16px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}
.contentTask {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px 8px 28px;
  cursor: pointer;
  color: var(--bw-900-b, #000);

  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  &:hover {
    border-radius: 8px;
    background-color: #f5f5f5;
  }
}

.add-theme-input {
  width: 100%;
  height: 33px;
  border: none;
}
.loadingContainer {
  display: flex;
  justify-content: flex-end;
  width: 96%;
  position: sticky;
  top: 32px;
  .loading {
    display: flex;
    align-items: center;
    gap: 18px;

    .spinIsLoading {
      display: flex;
      align-items: center;
    }
    .txt {
      color: #5c5c5c;
      font-family: "Nunito Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.activeTask {
  border-radius: 8px;
  background-color: #f5f5f5;
}

@media (max-width: 990px) {
  .root {
    border-radius: 0;
  }
  .items {
    flex-direction: column;
  }
  .root .items .left-section {
    width: 100%;
    padding-right: 32px;
  }
  .text-editor {
    overflow: auto;
  }
}
